<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" dark v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-delete-1 text-danger"></i>
          </span>
          <span class="navi-text">O'chirish </span>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">O'chirish</span>
        </v-card-title>
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="dialog = false">Bekor qilish</v-btn>
          <v-btn color="error" @click="submitDelete">O'chirish</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    submitDelete() {
      this.$store.dispatch('deleteCategoryProfit', this.actionObj.id)
      this.dialog = false
    }
  },
  props: {
    actionObj: Object
  }
}
</script>
<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
