<template>
  <div>
    <v-dialog v-model="dialogCreate" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small color="success" dark v-bind="attrs" v-on="on">
          {{ $t('MENU.ENTERPRICE.INCOMES.CATEGORY') }} +
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Kategoriya Daromad qo'shish</span>
        </v-card-title>
        <v-card-text>
          <div style="display: flex; flex-wrap: wrap">
            <div class="col-6 pl-0">
              <v-text-field
                label="Kategoriya daromad nomi"
                v-model="nameInput"
                outlined
                dense
              ></v-text-field>
            </div>

            <div class="col-6 pr-0">
              <v-text-field
                label="Kod"
                v-model="codeInput"
                maxLength="4"
                outlined
                dense
                minLength="4"
              ></v-text-field>
            </div>
            <div class="col-12 px-0">
              <v-autocomplete
                :items="getData"
                v-model="selectMainProfit"
                dense
                outlined
                item-text="name"
                item-value="id"
                label="Asosiy daromadlar"
              ></v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="newmainprofitCategory"
            color="warning"
            @click="dialogCreate = false"
            >Bekor qilish</v-btn
          >
          <v-btn
            :disabled="newmainprofitCategory"
            color="success"
            @click="submit"
          >
            <i v-if="newmainprofitCategory" class="el-icon-loading"></i>
            Yuborish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newmainprofitCategory: false,
      dialogCreate: false,
      selectMainProfit: '',
      nameInput: '',
      codeInput: ''
    }
  },
  created() {
    this.$store.dispatch('getMainProfit')
  },
  computed: {
    getData() {
      return this.$store.state.requests.mainProfit
    }
  },
  methods: {
    submit() {
      const data = {
        name: this.nameInput,
        code: this.codeInput,
        main_type: this.selectMainProfit
      }
      this.newmainprofitCategory = true
      this.$store
        .dispatch('createCategoryProfit', data)
        .then(() => {
          this.newmainprofitCategory = false
          this.nameInput = ''
          this.codeInput = ''
          this.selectMainProfit = ''
          this.dialogCreate = false
        })
        .catch((err) => {
          this.newmainprofitCategory = false
          console.error(err)
        })
    }
  }
}
</script>

<style></style>
