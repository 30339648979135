<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" dark v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('ACTION.UPDATE') }}</span>
        </v-card-title>
        <v-card-text>
          <div style="display: flex; flex-wrap: wrap">
            <div class="col-6 pl-0">
              <v-text-field
                outlined
                label="Nomi"
                dense
                v-model="data.name"
              ></v-text-field>
            </div>
            <div class="col-6 pr-0">
              <v-text-field
                outlined
                label="Kod"
                dense
                maxLength="4"
                minLength="4"
                v-model="data.code"
              ></v-text-field>
            </div>

            <div class="col-12 px-0">
              <v-autocomplete
                v-model="data.main_type"
                :items="getData"
                outlined
                dense
                label="Asosiy daromad"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="dialog = false">Yopish</v-btn>
          <v-btn color="success" @click="submitUpdate">Saqlash</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data: {}
    }
  },
  created() {
    this.data = Object.assign({}, this.actionObj)
    this.data = JSON.parse(JSON.stringify(this.actionObj))
  },
  computed: {
    getData() {
      return this.$store.state.requests.mainProfit
    }
  },
  methods: {
    submitUpdate() {
      const id = {
        id: this.data.id
      }
      const data = {
        name: this.data.name,
        code: this.data.code,
        main_type: this.data.main_type
      }
      this.$store.dispatch('updateCategoryProfit', { id, data })
      this.dialog = false
    }
  },
  props: {
    actionObj: Object
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
